/*
Inter Light: font-weight: 300
Inter Regular: font-weight: 400
Inter Medium: font-weight: 500
Inter SemiBold: font-weight: 600
Inter Bold: font-weight: 700
*/

:root {
    --bley: #BCCBCC; /* rgba(188, 203, 204, 1) */
    --bley-darker: #ADBABC; /* rgba(173, 186, 188, 1) */
    --blight: #D9D9D9; /* rgba(217, 217, 217, 1) */
    --bleu: #24305B; /* rgba(36, 48, 91, 1) */
    --bleu-darker: #151f4b; /* rgba(21, 31, 75, 1) */
    font-size: 10px;
}


/* Dark/light mode to be added

:root[data-theme="standard"]{

}

:root[data-theme="light"]{
    
}

:root[data-theme="dark"]{

}

*/


* {
    text-decoration: none;
    font-family: Inter, sans-serif;
}

body {
    margin: 0;
    background-color: var(--bley);
    font-family: Inter, sans-serif;
    font-weight: 400;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3em 2.5em;
    margin: 0 10%;
}

.nav-items {
    list-style: none;
    display: flex;
    text-transform: uppercase;
}

.nav-items > li {
    padding: 2em;
    font-size: 2em;
}

.nav-items > li > a {
    color: black;
    transition: 0.2s;
    align-content: center;
}

.nav-items > li > a:hover {
    color: white;
}

.in-app-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3em 0;
    margin: 0;
}

.in-app-nav-items {
    list-style: none;
    display: flex;
    text-transform: uppercase;
    padding: 0;
}

.in-app-nav-items > .in-app-li {
    padding: 2em;
    padding-right: 0;
    padding-left: 4em;
    font-size: 2em;
}

.in-app-nav-items > .in-app-li > a {
    color: white;
    transition: 0.2s;
}

.in-app-nav-items > .in-app-li > a:hover {
    color: black;
}

a:visited {
    color: inherit;
}

.logo {
    width: 17vw;
}

.logo-in-app {
    width: 12vw;
}

.left_right_container {
    display: flex;
    column-gap: 1cap;
    row-gap: 1cap;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 10%;
}

.media_box {
    max-width: 100%;
    padding-right: 2.5em;
    overflow: hidden;
}

.media_box > img {
    padding-left: 2.5em;    
    max-width: 100%;
}

.laptop-pic {
    grid-area: laptop-pic;
    max-width: 100%;
    justify-content: center;
    overflow: hidden;
}


/* css for elements that are hidden until in viewport */
.hidden {
    opacity: 0;
    filter: blur(4px);
    transform: translateX(-100%);
    transition: opacity 1s ease 1250ms, filter 1s ease 1250ms, transform 1s ease 1250ms;
}

/* if user doesn't like motion */
@media (prefers-reduced-motion) {
    .hidden {
        transition: none;
    }
}

/* css for elements that are viewed when in viewport */
.show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
    transition: opacity 1s ease 0s, filter 1s ease 0s, transform 1s ease 0s;
}

/* "CASH POOLING" so that it appears delayed, once in viewport */
.main_section1_content_element:nth-child(1) {
    transition-delay: 0ms;
    transform: translateX(0);
    filter: blur(0);
}

/* "BUT EFFORTLESS" so that it appears delayed, once in viewport */
.main_section1_content_element:nth-child(2) {
    transition-delay: 200ms;
    /* transform: translateX(0); */
    /* filter: blur(0); */
}

/* "Manage your cash pools with precise..." so that it appears delayed, once in viewport */
.main_section1_content_element:nth-child(3) {
    transition-delay: 1000ms;
    transform: translateX(0);
    filter: blur(0);
}

/* "Schedule Call" button so that it appears delayed, once in viewport */
.main_section1_content_element:nth-child(4) {
    transition-delay: 1250ms;
    transform: translateX(0);
    filter: blur(0);
}

/* "Transfer price algorithm for OECD compliance" feature so that it appears delayed, once in viewport */
.main--feature:nth-child(1) {
    transition-delay: 1500ms;
}

/* "Export reports, graphs, and assessments" feature so that it appears delayed, once in viewport */
.main--feature:nth-child(2) {
    transition-delay: 1700ms;
}

/* "Foresee structural risks" feature so that it appears delayed, once in viewport */
.main--feature:nth-child(3) {
    transition-delay: 1900ms;
}

.main_section1 {
    background-color: var(--bley);
}

.main_section1_content {
    padding: 2.5em;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    grid-template-areas:
        "cash-pooling laptop-pic-front"
        "but-effortless laptop-pic-front"
        "manage-your-etc laptop-pic-front"
        "header-button laptop-pic-front"
        "features features";
    column-gap: 1cap;
    row-gap: 1cap;
    justify-content: space-between;
    align-items: flex-start;
}

.main_section1_content_2 {
    padding: 2.5em;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    grid-template-areas:
        "laptop-pic main-section1-content-2-header"
        "laptop-pic main-section1-content-2-p1"
        "laptop-pic main-section1-content-2-p2"
        "laptop-pic main-section1-content-2-p3";
    column-gap: 1cap;
    row-gap: 1cap;
    justify-content: space-between;
    align-items: flex-start;
}

.cash-pooling {
    grid-area: cash-pooling;
}

.but-effortless {
    grid-area: but-effortless;
}

.manage-your-etc {
    grid-area: manage-your-etc;
}

.laptop-pic-front {
    grid-area: laptop-pic-front;
    max-width: 100%;
    /* padding-right: 2.5em; */
    justify-content: center;
    overflow: hidden;
}

.main_section1_content > .button {
    grid-area: header-button;
    width: fit-content;
}

.main_section1_content > h1, h2 {
    font-size: 6em;
    font-weight: 400;
    text-transform: uppercase;
    margin: 1.5rem 0rem;
}

.main_section1_content > h1 {
    color: black;
}

.main_section1_content > h2 {
    color: white;
}

.main_section1_content_2 > h3 {
    font-size: 3.6em;
    font-weight: 400;
    color: black;
}

.main_section1_content  > p, .main_section1_content_2 > p {
    font-size: 2.4em;
    font-weight: 400;
    color: black;
}

.main_section1_content_2_header {
    grid-area: main-section1-content-2-header;
}

.main_section1_content_2_p1 {
    grid-area: main-section1-content-2-p1;
}

.main_section1_content_2_p2 {
    grid-area: main-section1-content-2-p2;
}

.main_section1_content_2_p3 {
    grid-area: main-section1-content-2-p3;
}


.button_call {
    background-color: white; 
    color: black; 
    padding: 0.4167em;
    font-size: 2em;
    border-radius: 2.5rem;
    border-style: none;
    transition: background-color 0.2s ease, filter 0.2s ease, color 0.2s ease, opacity 1s ease 1250ms !important;
    cursor: pointer;
}

.button_call:hover {
    background-color: var(--bleu); 
    color: var(--bley);
    filter: drop-shadow(0 0.167em 0.167em rgba(0, 0, 0, 0.25));
}

.main--features {
    padding: inherit;
    padding-left: 0.7em;
    margin: 0;
    grid-area: features;
}

.error-message {
    color: red;
  }

.main--features > li {
    color: black;
    font-size: 2.4em;
    padding: 0.208em 0.625em;
    grid-area: main--feature;
}

.main--features > li::marker {
    font-size: 0.625em;
    font-weight: 700;
    content: "\20D8 ";
}


.main_section2 {
    background-color: var(--blight);
}

.main_section2_content {
    padding: 2.5em;
    margin: 0 10%;
}

.benefits_section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 2.4em 0;
}

.benefits_section h3 {
    font-size: 3.6rem;
    font-weight: 400;
    margin: 0;
    color: black;
    z-index: 2;
    position: relative;
}

.benefits_section p {
    font-size: 2.4rem;
    font-weight: 400;
    color: black;
    position: relative;
    z-index: 2;
}

.text_left {
    text-align: center;
    max-width: 45%;
    position: relative;
    z-index: 2;
}

.text_right {
    text-align: center;
    max-width: 45%;
    position: relative;
    z-index: 2;
}

.arrow_left_top {
    max-width: 50%;
    z-index: 1;
}

.arrow_right {
    max-width: 50%;
    transform: rotate(180deg);
    z-index: 1;
}

.arrow_left_bottom {
    max-width: 50%;
    transform: rotateX(180deg);
    z-index: 1;
}


.main_section3 {
    background-color: var(--bley);
}

.main_section3_content {
    padding: 2.5em;
    margin: 0 10%;
}

.testimonials {
    display: block;
    text-align: center;
    padding: 2.4em 0;
}

.testimonials > h3 {
    font-size: 3.6em;
    font-weight: 400;
    margin: 0;
}

.testimonials_box {
    display: flex;
    justify-content: space-between;
    gap: 2.5em;
    align-items: flex-start;
    padding: 2.4em 0 2.5em 0;
}

.testimonials_content {
    text-align: left;
}

.testimonials_text, .testimonials_person {
    margin: 0;
    font-size: 2.4em;
}

.testimonials_rating {
    padding: 0.4167em 0;
    color: white;
    font-size: 2.4em;
}

.features {
    display: block;
    text-align: center;
    padding: 2.4em;
    background-color: var(--blight);
    border-radius: 25px;
}

.features > h3 {
    font-size: 3.6em;
    font-weight: 400;
    margin: 0;
}

.features_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 2.5em;
    align-items: flex-start;
    padding-top: 2.4em;
}

.list_features {
    font-size: 2.4em;
    text-align: left;
    padding-left: 1em;
    margin: 0;
}

.list_features > li {
    color: black;
    font-size: 1em;
    padding: 0.208em 0.625em;
}

.list_features > li::marker {
    font-size: 0.625em;
    font-weight: 700;
    content: "\20D8 ";
}

.call_to_action {
    display: block;
    text-align: center;
    padding: 2.4em 0;
}

.call_to_action h3 {
    font-size: 3.6em;
    font-weight: 400;
    padding: 2.4rem 0;
    margin: 0;
}


@media (min-width: 2000px) {

    .main_section1_content {
        grid-template-columns: 1.5fr 1fr;
    }

    .main_section1_content_2 {
        grid-template-columns: 1fr 2fr;
    }
}

@media (max-width: 1510px) {
    .main_section1_content > h1, h2 {
        font-size: 5em;
    }
}


@media (max-width: 1322px) {

    .main_section1_content {
        grid-template-columns: 1fr;
        grid-template-areas:
            "cash-pooling"
            "but-effortless"
            "manage-your-etc"
            "laptop-pic-front"
            "header-button"
            "features";
    }

    .main_section1_content > p, .main_section1_content_2 > p {
        font-size: 2.4em;
    }

    .main--features > li {
        font-size: 2em;
        padding: 0.7em;
    }

    .main_section1_content > .button {
        justify-self: center;
    }

    .main_section1_content_2 {
        grid-template-columns: 1fr;
        grid-template-areas:
            "main-section1-content-2-header"
            "main-section1-content-2-p1"
            "main-section1-content-2-p2"
            "laptop-pic"
            "main-section1-content-2-p3";
    }

    .benefits_section > .text_right, .benefits_section > .text_left {
        max-width: 100%;
    }

}


@media (max-width: 1200px) {
    
    .main_section2 {
        overflow: hidden;
    }

    .benefits_section {
        flex-direction: column-reverse;
    }
    
    .arrow_left_top {
        position: absolute;
        left: 65vw;
    }

    .arrow_right {
        position: absolute;
        right: 65vw;
    }

    .arrow_left_bottom {
        position: absolute;
        left: 65vw;
    }

    .main_section3_content {
        margin: 0
    }
    
    .features_content {
        grid-template-columns: 1fr;
        gap: 0;
    }

}


@media (max-width: 750px) {
    .main_section1_content > h1, h2 {
        font-size: 3em;
        margin: 0;
    }

    .main_section1_content > p, .main_section1_content_2 > p {
        font-size: 1.8em;
    }

    .main--features > li {
        font-size: 1.6em;
        padding: 0 0 0.7em 0.7em;
    }

    .button_call {
        font-size: 1.6em;
    }

    .main_section1_content > .button {
        justify-self: center;
    }

    .main_section1_content_2 > h3 {
        font-size: 2.4em;
    }

    .benefits_section h3 {
        font-size: 2.4em;
    }

    .benefits_section p {
        font-size: 1.8em;
    }

    .features > h3 {
        font-size: 2.4em;
    }
    
    .list_features {
        font-size: 1.8em;
        max-width: 100%;
    }

    .call_to_action h3 {
        font-size: 2.4em;
    }
}

@media (max-width: 600px) {
    
    .left_right_container {
        display: block;
        /* flex-direction: row; */
        align-items: center;
        margin: 0 10%;
    }

    .media_box {
        max-width: 100%;
    }

    .main_section1_content {
        max-width: 100%;
    }

    .main--features > .main--feature {
        font-size: 1.6em;
    }

    .main_section1_content_2 > h3 {
        font-size: 2em;
    }

    .main_section1_content_2 > p {
        font-size: 1.6em;
    }

    .benefits_section h3 {
        font-size: 2em;
    }
    
    .benefits_section p {
        font-size: 1.6em;
    }

    .features > h3 {
        font-size: 2em;
    }

    .list_features {
        font-size: 1.6em;
    }

    .call_to_action h3 {
        font-size: 2em;
    }
}



.footer {
    color: black;
    padding: 2.5em;
    text-align: center;
    font-size: 1.2em;
}

/* wrapper for pages */
.page-container {
    margin-left: 5vw;
    margin-right: 5vw;
}

.dashboard-greeting {
    font-size: 4em;
    font-weight: 400;
}

.dashboard-chart-and-synergy {
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    grid-auto-rows: auto;
    grid-template-areas:
        "dashboard-data-options dashboard-data-options"
        "dashboard-data-chart dashboard-data-div";
    column-gap: 1cap;
    row-gap: 1cap;
    margin: 2.5em 0;
    padding: 1.5em 1em;
    border-radius: 15px;
    border: 1px solid white;
    /* justify-content: space-between; */
    align-items: end;
    position: relative;
    z-index: 2;
}

.dashboard-data-options {
    grid-area: dashboard-data-options;
    /* align-self: start; */
    width: 100%;
    align-items: center;
    /* justify-content: center; */
    /* padding: 15px; */
    /* margin-bottom: 1.5em; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 1cap;
}

.dashboard-data-options-datepicker {
    /* padding-left: 1rem; */
    padding-right: 21px;
}

.dashboard-data-chart {
    grid-area: dashboard-data-chart;
}

.dashboard-data-div {
    display: flex;
    flex-direction: column;
    /* margin: 1em; */
    width: 100%;
    height: 300px; /* make this same height as chart */
    align-items: center;
    justify-content: center;
    grid-area: dashboard-data-div;
}

.dashboard-data-number {
    font-size: 1em;
    font-weight: 600;
    margin: 1rem;
    grid-area: dashboard-data-number;
}

.dashboard-data-number-boxes {
    width: 100%;
    height: 100%;
    font-size: 1.4rem;
    font-weight: 300;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    grid-template-areas:
        "total-synergy total-synergy"
        "volume net-balance"
        "cpp-synergy ctah-synergy";
    column-gap: 1cap;
    row-gap: 1cap;
}

.dashboard-data-number-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    border: 1px solid white;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.dashboard-data-number-box-number {
    font-size: 1.6rem;
    font-weight: 400;
}

.dashboard-data-number-box-volume {
    grid-area: volume;
}

.dashboard-data-number-box-balance {
    grid-area: net-balance;
}

.dashboard-data-number-box-cpp-synergy {
    grid-area: cpp-synergy;
}

.dashboard-data-number-box-ctah-synergy {
    grid-area: ctah-synergy;
}

.dashboard-data-number-box-total-synergy {
    grid-area: total-synergy;
}

/* div of table toolbar */
.table-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.table-toolbar-companies {
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(3, 1fr);
    /* justify-content: space-between; */
    align-items: start;
}

/* top-right button for table to create new cash pool or update balances */
.table-toolbar-button {
    justify-self: end;
    background-color: white;
    width: fit-content;
    padding: 1rem 3rem;
    border-bottom-left-radius: 5rem;
    border-width: 0;
    font-size: 1.6rem;
    color: rgba(0, 0, 0, 0.6);
    transition: 0.2s;
    cursor: pointer;
}

.table-toolbar-button:visited:hover {
    background-color: var(--bleu);
    color: var(--bley);
}

.table-toolbar-button:hover {
    background-color: var(--bleu);
    color: var(--bley);
}
/* tooltip when hovering over header in mui datagrid table (gotta add the class to the description in column const) */
.table-tooltip {
    font-size: 1rem;
    margin: 0;
}

.companies_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.companies_header > h1 {
    font-weight: 400;
    font-size: 4.8em;
    margin: 0;
}

.companies_header_buttons {
    display: flex;
    gap: 2em;
}

.cashpool_data_container {
    font-size: 2em;
    margin: 1.25em 0;
    padding: 0 0.5em;
    border-radius: 15px;
    border: 1px solid white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
}

.cashpool_data {
    margin: 0.75em 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cashpool_data_table_cell {
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}

.cashpool_data_table_cell > th {
    font-weight: 700;
}

/* datepicker container */
.react-datepicker__input-container {
    font-size: 1.6em;
    display: flex;
    align-items: center;
}

/* datepicker calendar icon */
.react-datepicker__input-container .react-datepicker__calendar-icon {
    vertical-align: middle;
    padding: 0 10px !important;
    height: 100%;
    padding-left: 6px;
    cursor: pointer;
}

/* datepicker input text */
.datepicker-input-class {
    font-size: 1em;
    background-color: var(--blight);
    border: none;
    border-radius: 10px;
    padding-left: 35px;
    padding: 6px 35px 5px 41px !important;
    width: 78%;
    cursor: pointer;
    color: black;
}

.react-datepicker__close-icon {
    display: inline-flex !important;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    align-items: center !important;
    justify-content: start !important;
    right: -10px !important;
    height: 100% !important;
    border-radius: 50%;
}

.react-datepicker__close-icon::after {
    display: inline-block !important;
    content: url("data:image/svg+xml,%3Csvg fill='rgba(0, 0, 0, 0.54)' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'%3E%3C/path%3E%3C/svg%3E") !important;
    background-color: transparent !important;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-self: center;
    outline: none;
    padding: 5px !important;
    width: 1em !important;
    height: 1em !important;
    font-size: 1.125em !important;
}

.react-datepicker__close-icon:hover::after {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.react-datepicker__triangle {
    color: rgba(0, 0, 0, 0.04) !important;
    fill: var(--blight) !important;
}

.react-datepicker {
    font-family: Inter, sans-serif !important;
    width: fit-content;
    font-size: 1.6rem !important;
    background-color: var(--blight) !important;
}

.react-datepicker-popper {
    z-index: 5 !important;
    overflow: visible !important; /* the mui datagrid still hides the overflow... */
    /* overflow-y: visible !important;
    overflow-y: visible !important; */
}

.react-datepicker__month-container {
    width: fit-content;
}

.react-datepicker__header  {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.react-datepicker__current-month {
    font-size: 1.6rem !important;
    padding: 0.5rem 0;
}

.react-datepicker__navigation {
    top: 8px !important;
}

.react-datepicker__navigation-icon::before {
    border-color: black !important;
    border-width: 2px 2px 0 0 !important;
}

.react-datepicker__month {
    width: fit-content;
}

.react-datepicker__day-names {
    margin: 0 0.4rem;
}

.react-datepicker__day-name {
    width: 3rem !important;
    margin: 0.1667rem 0 !important;
}

/* .react-datepicker__week {
    width: 100%;
} */

.react-datepicker__day {
    width: 3rem !important;
    margin: 0.1667rem 0 !important;
    line-height: 2rem !important;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end) {
    background-color: var(--bley) !important;
    border-radius: 0 !important;
}

.react-datepicker__day--selecting-range-start {
    border-radius: 1rem 0 0 1rem !important;
    background-color: var(--bley-darker) !important;
}

.react-datepicker__day--selecting-range-end {
    border-radius: 0 1rem 1rem 0 !important;
    background-color: var(--bley-darker) !important;
}

.react-datepicker__day--in-range {
    border-radius: 0 !important;
    background-color: var(--bley-darker) !important;
}

.react-datepicker__day--range-start {
    background-color: var(--bley-darker) !important;
    border-radius: 1rem 0 0 1rem !important;
}

.react-datepicker__day--range-end {
    border-radius: 0 1rem 1rem 0 !important;
}

.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end {
    border-radius: 1rem !important;
}

.react-datepicker__day--range-start.react-datepicker__day--range-end {
    border-radius: 1rem !important;
}

.react-datepicker__day--selected:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--in-range, .react-datepicker__day--range-start) {
    background-color: var(--bley-darker) !important;
    border-radius: 1rem !important;
}

.react-datepicker__day--in-selecting-range:hover { 
    background-color: var(--bley-darker) !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: #f0f0f0 !important;
}

.companies_chart_and_bar {
    display: grid;
    grid-template-columns: 1fr 1.3fr;
    grid-auto-rows: auto;
    grid-template-areas:
        "companies_chart_and_bar-datepicker ."
        "companies_chart_and_bar-barchart companies_chart_and_bar-linechart";
    column-gap: 1cap;
    margin: 2.5em 0;
    padding: 1.5em 1em;
    border-radius: 15px;
    border: 1px solid white;
    /* justify-content: space-between; */
    align-items: end;
    position: relative;
    z-index: 2;
}

.companies_chart_and_bar-datepicker {
    grid-area: companies_chart_and_bar-datepicker;
    align-self: start;
}

.companies_table-datepicker {
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CustomDatePickerDay-div {
    display: flex;
    gap: 0.75em;
    margin: 0.625em;
    padding: 0 0.75em;
    align-items: center;
}

.CustomDatePickerDay-div:hover .react-datepicker__navigation-icon--previous-custom::before, .CustomDatePickerDay-div:hover .react-datepicker__navigation-icon--next-custom::before {
    display: block !important;
}

.react-datepicker__navigation-icon-custom::before {
    border-color: rgba(0, 0, 0, 0.6) !important;
    border-width: 1px 1px 0 0 !important;
    height: 0.5em !important;
}

.react-datepicker__navigation-icon-custom:hover::before {
    border-color: black !important;
}

.datepicker-nav-button-div {
    position: relative;
    width: 1em;
    height: 1em;
    border-radius: 50px;
}

.datepicker-nav-button-div:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
}

.react-datepicker__navigation-icon--previous-custom::before {
    position: relative;
    transform: rotate(225deg);
    right: -6px;
    top: 5px;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    height: 0.35em !important;
    width: 0.35em !important;
    display: none !important;
}

.react-datepicker__navigation-icon--next-custom::before {
    transform: rotate(45deg);
    position: relative;
    left: 3.5px;
    top: 5px;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    height: 0.35em !important;
    width: 0.35em !important;
    display: none !important;
}

.custom-datepicker-input-button {
    font-size: 1em;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
}

.custom-datepicker-input-button:hover {
    color: black;
}

.companies_chart_and_bar-barchart {
    grid-area: companies_chart_and_bar-barchart;
}

.companies_chart_and_bar-linechart {
    grid-area: companies_chart_and_bar-linechart;
}

.line_solid {
    width: 100%;
    border-bottom: 1px solid white;
}

/* .accordion {
} */

.accordion_label {
	font-size: 2em;
    font-weight: 600;
    padding: 1.5rem 0;
	display: block;
	cursor: pointer;
	position: relative;
	transition: 'background 0.1s';
	padding-right: 40px;
}

.accordion_label::after {
	content: "";
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 17px;
	width: 12px;
	height: 6px;
	background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:black;" /></svg>');
	background-size: contain;
	transition: transform 0.4s;
}

.accordion_content {
	display: none;
}

.accordion_input {
	display: none;
}

.accordion_input:checked ~ .accordion_content {
	display: block;
}

.accordion_input:checked ~ .accordion_label::after {
	transform: translateY(-50%) rotate(0.5turn);
}

.cashpool_settings_container {
    margin: 2.5em 0;
    padding: 0 1em;
    padding-top: 0;
    border-radius: 15px;
    border: 1px solid white;
}

.cashpool_settings_info > h1 {
    font-size: 2em;
}
.UploadCompaniesModal-h3 {
    font-size: 2em;
    padding: 1.6rem 3.8rem 1.6rem 1.6rem;
    margin: 0;
}

.UploadCompaniesModal-p {
    font-size: 1.4em;
    padding: 1.6rem 1.6rem 0 1.6rem;
    margin: 0;
}

.upload-participants-container {
    height: 300px;
    border-radius: 25px;
    outline: black;
    outline-style: dashed;
    outline-width: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5em;
}

.upload-participants-container > h4 {
    font-size: 2em;
    font-weight: 500;
    margin: 0.75em;
}

.upload-participants-container > input {
    padding: 1.5em;
}

.uploaded-file-ul {
    font-size: 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.uploaded-file-ul > p {
    margin: 0;
}

.uploaded-file-name {
    font-weight: 300;
    margin: 0;
}

.uploaded-file-submit {
    display: flex;
    justify-content: center;
}

.loading_circle_div {
    display: flex;
    justify-content: center;
}

.status_message {
    font-size: 1.5em;
    margin: 1em 0;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/* not sure if this is used? */
.settings {
    margin: 0;
}

.settingsTable > tr {
    display: flex;
    text-align: left;
    align-items: center;
    font-size: 2em;
    row-gap: 2em;
}

.settingsTable > tr > th {
    font-weight: 400;
    width: 200px;
    padding: 0.5em 1em 0.5em 0;
}

.settingsTable > tr > td {
}

/* used for Save Button in Settings (so that it is on right side). */
.button_container {
    display: flex;
    justify-content: end;
}

.cancel-save-buttons-div {
    display: flex;
    justify-content: space-between;
}

/* text/ label for toggle switch in settings */
.button-text-box {
    display: flex;
    align-items: center;
    gap: 0.75em;
}

/* text/ label for toggle switch in settings */
.button-text {
    display: flex;
    align-items: center;
    gap: 0.75em;
    color: grey;
}

.button-text > p {
    margin: 0;
}

/* CSS for toggle switch */

.checked_option {
    color: black;
}

input[type="checkbox"].toggle-switch {
    opacity: 0;
    position: absolute;
    left: -101vw;
}

input[type="checkbox"].toggle-switch:focus-visible + label::before {
    outline: 1px solid var(--bleu);
}

input[type="checkbox"].toggle-switch + label {
    position: relative;
    display: flex;
    align-items: center;
    color: grey;
    cursor: pointer;
    user-select: none;
    transition: 200ms ease-in-out;
}

input[type="checkbox"].toggle-switch + label::before {
    content: "";
    width: 2em;
    height: 1em;
    background-color: var(--bley-darker);
    border-radius: 1em;
    transition: 200ms ease-in-out;
}

input[type="checkbox"].toggle-switch + label::after {
    position: absolute;
    left: 0.15em;
    content: "";
    width: 0.7em;
    height: 0.7em;
    background-color: black;
    border-radius: 1em;
    transition: 200ms ease-in-out;
}

input[type="checkbox"].toggle-switch:checked + label::after {
    transform: translateX(142.85%);
}

input[type="checkbox"].toggle-switch:checked + label {
    color: black;
    transition: 200ms ease-in-out;
}

.export-modal-datepicker{
    padding: 0 0 1.6rem 0;
}

.Excel-PDF-Export-Selector-div {
    display: flex;
    /* height: 2rem; */
    width: 100%;
    /* background-color: var(--bley-darker); */
    border-radius: 15px;
    gap: 0.25rem;
    padding: 0 0 1.6rem 0;
}

.Excel-PDF-Export-Selector-button-excel {
    width: 100%;
    font-size: 2em;
    font-weight: 400;
    color: black;
    text-transform: uppercase;
    background-color: var(--bley-darker);
    padding: 1rem;
    border: none;
    border-radius: 15rem 0 0 15rem;
    cursor: pointer;    
}

.Excel-PDF-Export-Selector-button-pdf {
    width: 100%;
    font-size: 2em;
    font-weight: 400;
    color: black;
    text-transform: uppercase;
    background-color: var(--bley-darker);
    padding: 1rem;
    border: none;
    border-radius: 0 15rem 15rem 0;
    cursor: pointer;    
}

.Excel-PDF-Export-Selector-button-pdf:hover, .Excel-PDF-Export-Selector-button-excel:hover {
    background-color: rgba(0, 0, 0, 0.12);
}

.Excel-PDF-Export-Selector-button-pdf.active, .Excel-PDF-Export-Selector-button-excel.active {
    background-color: rgba(0, 0, 0, 0.12);
}

/* Checkboxes for export modal */

.export_options_container {
    margin-bottom: 2em;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.checkbox-container {
    font-size: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.checkbox-container > p {
    font-size: 1.5em;
    font-weight: 200;
}

.checkbox-container > label {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    font-size: 2em;
    gap: 0.5em;
}

.checkbox-container > input[type='checkbox'] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
}

.checkbox-container > label::before {
    content: '';
    min-width: 1em !important;
    max-width: 1em !important;
    height: 1em !important;
    position: relative;
    top: 0.125em;
    background-color: var(--bley-darker);
    /* background-color: rgba(0, 0, 0, 0.12); */
}

.checkbox-container > label:hover::before,
.checkbox-container > input[type='checkbox']:hover + label::before,
.checkbox-container > input[type='checkbox']:focus + label::before {
    background-color: rgba(0, 0, 0, 0.12);
}

.checkbox-container > input[type='checkbox']:checked + label::before {
    content: url('./images/checkmark.svg');
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Recharts legend and axes */
.recharts-legend-wrapper, .recharts-cartesian-axis-ticks {
    font-size: 1.2em;
}

/* Recharts tooltip */
.custom-tooltip {
    font-size: 1.6rem;
    padding: 10px;
    background-color: rgba(188, 203, 204, 0.25);
    color: black;
    border-radius: 5px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    /* underneath is for blur. tbd if with or w/o blur is best. if w/o, then increase opacity of bg. */
    backdrop-filter: blur(1.7px);
    -webkit-backdrop-filter: blur(1.7px);
    border: 1px solid rgba(188, 203, 204, 0.2);
}

.custom-tooltip > .tooltip-date {
    font-weight: 700;
    margin: 0;
}

.custom-tooltip > .tooltip-items {
    font-weight: 400;
}

.custom-tooltip > .tooltip-items > .tooltip-item {
    padding-top: 4px;
    padding-bottom: 4px;
}

.MuiAutocomplete-noOptions {
    background-color: #ADBABC,
}

.create-cashpool-grid {
    display: grid;
    /* height: 100vh; */
    grid-template-columns: 215px 1fr 367px 1fr;
    grid-template-rows: repeat(5, 45.75px);
    grid-template-areas:
        "create-cashpool-header-name create-cashpool-input-name create-cashpool-header-type create-cashpool-input-type"
        "create-cashpool-header-currency create-cashpool-input-currency create-cashpool-header-country create-cashpool-input-country"
        "create-cashpool-header-leader create-cashpool-input-leader . ."
        "create-cashpool-header-rate create-cashpool-input-rate create-cashpool-header-deprate create-cashpool-input-deprate"
        "create-cashpool-header-refrate create-cashpool-input-refrate create-cashpool-header-withrate create-cashpool-input-withrate";
    grid-gap: 1cap;
    margin: 1.5em 0;
}

.create-cashpool-header {
    text-transform: none;
    font-size: 2em;    /* Make this work with the input fields */
    padding: 11px 15px 9px 0px;     /* Make this work with the input fields */
    margin: 0;
}

#create-cashpool-header-name {
    grid-area: "create-cashpool-header-name";
}

#create-cashpool-input-name {
    grid-area: "create-cashpool-input-name";
}

#create-cashpool-header-type {
    grid-area: "create-cashpool-header-type";
}

#create-cashpool-input-type {
    grid-area: "create-cashpool-input-type";
}

#create-cashpool-header-currency {
    grid-area: "create-cashpool-header-currency";
}

#create-cashpool-input-currency {
    grid-area: "create-cashpool-input-currency";
}

#create-cashpool-header-country {
    grid-area: "create-cashpool-header-country";
}

#create-cashpool-input-country {
    grid-area: "create-cashpool-input-country";
}

#create-cashpool-header-leader {
    grid-area: "create-cashpool-header-leader";
}

#create-cashpool-input-leader {
    grid-area: "create-cashpool-input-leader";
}

#create-cashpool-header-rate {
    grid-area: "create-cashpool-header-rate";
    grid-row: 4;
    grid-column: 1;
}

#create-cashpool-input-rate {
    grid-area: "create-cashpool-input-rate";
    grid-row: 4;
    grid-column: 2;
    padding: 11px 15px 9px 0px;
    font-size: 2em;
}

#create-cashpool-header-refrate {
    grid-area: "create-cashpool-header-refrate";
    grid-row: 5;
    grid-column: 1;
}

#create-cashpool-input-refrate {
    grid-area: "create-cashpool-input-refrate";
    grid-row: 5;
    grid-column: 2;
}

#create-cashpool-header-deprate {
    grid-area: "create-cashpool-header-deprate";
    grid-row: 4;
    grid-column: 3;
}

#create-cashpool-input-deprate {
    grid-area: "create-cashpool-input-deprate";
    grid-row: 4;
    grid-column: 4;
}

#create-cashpool-header-withrate {
    grid-area: "create-cashpool-header-withrate";
    grid-row: 5;
    grid-column: 3;
}

#create-cashpool-input-withrate {
    grid-area: "create-cashpool-input-withrate";
    grid-row: 5;
    grid-column: 4;
}


@media only screen and (max-width:1352px) {

    .create-cashpool-grid {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(10, 45.75px);
        grid-template-areas:
            "create-cashpool-header-name create-cashpool-header-type"
            "create-cashpool-input-name create-cashpool-input-type"
            "create-cashpool-header-currency create-cashpool-header-country"
            "create-cashpool-input-currency create-cashpool-input-country"
            ". create-cashpool-header-leader"
            ". create-cashpool-input-leader"
            "create-cashpool-header-rate create-cashpool-header-deprate"
            "create-cashpool-input-rate create-cashpool-input-deprate"
            "create-cashpool-header-refrate create-cashpool-header-withrate" 
            "create-cashpool-input-refrate create-cashpool-input-withrate";
        row-gap: 1cap;
        column-gap: 2rem;
    }

    .create-cashpool-header {
        padding: 0;
    }

    #create-cashpool-header-name {
        grid-column: 1;
        grid-row: 1;
        margin-top: 1.5rem;
    }
    
    #create-cashpool-input-name {
        grid-row: 2;
        grid-column: 1;
    }

    #create-cashpool-header-type {
        grid-row: 1;
        grid-column: 2;
        margin-top: 1.5rem;
    }
    
    #create-cashpool-input-type {
        grid-row: 2;
        grid-column: 2;
    }

    #create-cashpool-header-currency {
        grid-row: 3;
        grid-column: 1;
        margin-top: 1.5rem;
    }
    
    #create-cashpool-input-currency {
        grid-row: 4;
        grid-column: 1;
    }

    #create-cashpool-header-country {
        grid-row: 3;
        grid-column: 2;
        margin-top: 1.5rem;
    }
    
    #create-cashpool-input-country {
        grid-row: 4;
        grid-column: 2;
    }
    
    #create-cashpool-header-leader {
        grid-row: 5;
        grid-column: 1;
        margin-top: 1.5rem;
    }
 
    #create-cashpool-input-leader {
        grid-row: 6;
        grid-column: 1;
    }

    #create-cashpool-header-rate {
        grid-area: "create-cashpool-header-rate";
        grid-row: 7;
        grid-column: 1;
        margin-top: 1.5rem;
    }
    
    #create-cashpool-input-rate {
        grid-area: "create-cashpool-input-rate";
        grid-row: 8;
        grid-column: 1;
        /* padding: 11px 15px 9px 0px; */
    }
    
    #create-cashpool-header-refrate {
        grid-area: "create-cashpool-header-refrate";
        grid-row: 9;
        grid-column: 1;
        margin-top: 1.5rem;
    }
    
    #create-cashpool-input-refrate {
        grid-area: "create-cashpool-input-refrate";
        grid-row: 10;
        grid-column: 1;
    }
    
    #create-cashpool-header-deprate {
        grid-area: "create-cashpool-header-deprate";
        grid-row: 7;
        grid-column: 2;
        margin-top: 1.5rem;
    }
    
    #create-cashpool-input-deprate {
        grid-area: "create-cashpool-input-deprate";
        grid-row: 8;
        grid-column: 2;
    }
    
    #create-cashpool-header-withrate {
        grid-area: "create-cashpool-header-withrate";
        grid-row: 9;
        grid-column: 2;
        margin-top: 1.5rem;
    }
    
    #create-cashpool-input-withrate {
        grid-area: "create-cashpool-input-withrate";
        grid-row: 10;
        grid-column: 2;
    }

    .settings-fr-grid {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width:804px) {

    .create-cashpool-grid {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(18, 45.75px);
        grid-template-areas:
            "create-cashpool-header-name"
            "create-cashpool-input-name"
            "create-cashpool-header-type"
            "create-cashpool-input-type"
            "create-cashpool-header-currency"
            "create-cashpool-input-currency"
            "create-cashpool-header-country"
            "create-cashpool-input-country"
            "create-cashpool-header-leader"
            "create-cashpool-input-leader"
            "create-cashpool-header-rate"
            "create-cashpool-input-rate"
            "create-cashpool-header-deprate"
            "create-cashpool-input-deprate"
            "create-cashpool-header-withrate"
            "create-cashpool-input-withrate"
            "create-cashpool-header-refrate"
            "create-cashpool-input-refrate";
        row-gap: 1cap;
    }

    #create-cashpool-header-name {
        grid-column: 1;
        grid-row: 1;
    }
    
    #create-cashpool-input-name {
        grid-column: 1;
        grid-row: 2;
    }

    #create-cashpool-header-type {
        grid-column: 1;
        grid-row: 3;
    }
    
    #create-cashpool-input-type {
        grid-column: 1;
        grid-row: 4;
    }

    #create-cashpool-header-currency {
        grid-column: 1;
        grid-row: 5;
    }
    
    #create-cashpool-input-currency {
        grid-column: 1;
        grid-row: 6;
    }

    #create-cashpool-header-country {
        grid-column: 1;
        grid-row: 7;
    }
    
    #create-cashpool-input-country {
        grid-column: 1;
        grid-row: 8;
    }
    
    #create-cashpool-header-leader {
        grid-column: 1;
        grid-row: 9;
    }
 
    #create-cashpool-input-leader {
        grid-column: 1;
        grid-row: 10;
    }

    #create-cashpool-header-rate {
        grid-column: 1;
        grid-row: 11;
    }
    
    #create-cashpool-input-rate {
        grid-column: 1;
        grid-row: 12;
        padding: 0;
    }

    #create-cashpool-header-deprate {
        grid-column: 1;
        grid-row: 13;
    }

    #create-cashpool-input-deprate {
        grid-column: 1;
        grid-row: 14;
    }

    #create-cashpool-header-refrate {
        grid-column: 1;
        grid-row: 17;
    }

    #create-cashpool-input-refrate {
        grid-column: 1;
        grid-row: 18;
    }

    #create-cashpool-header-withrate {
        grid-column: 1;
        grid-row: 15;
    }

    #create-cashpool-input-withrate {
        grid-column: 1;
        grid-row: 16;
    }
}

.settings-fr-grid {
    display: grid;
    /* height: 100vh; */
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 2cap;
    margin: 1.5em 0;
}

.settings-fr-header {
    font-size: 2em;    /* Make this work with the input fields */
    font-weight: 500;
    padding: 11px 15px 9px 0px;     /* Make this work with the input fields */
    margin: 0;
    text-transform: none;
}

.settings-fr-spread-div {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 2em;
}

.settings-fr-spread-div > h2 {
    font-size: 2rem;
    font-weight: 500;
    text-transform: none;
}

/*gonna delete .underline later, it's just for now*/
.underline {
    text-decoration: underline;
}
